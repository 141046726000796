import React, { useEffect } from 'react'
import cloche from '../../assets/images/cloche.svg';
import profil from '../../assets/images/profil.png';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Swal from 'sweetalert2';
import { endpoints } from '../../utils/utils';


const ListesCommande = ({ commandes, update }) => {

  const axiosPrivate = useAxiosPrivate();
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  const verify = async (token) => {
    try {
      const response = await axiosPrivate.post(endpoints.stripeVerify,
        JSON.stringify({ paymentIntentId: token }),
        {
          headers: { 'Content-Type': 'application/json' },

        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data && response?.data?.status !== false) {
        Swal.fire({
          title: `satus ${response?.data?.response}`,
          text: response?.data?.response
        })

      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'erreur paiement'
        })
      }

    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'erreur paiement'
      })
    }
    /*  console.log(doc) */

  }
  useEffect(() => {

  }, [commandes]);
// eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = React.useState(0)
  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const currentPageData = commandes
    ?.slice(offset, offset + PER_PAGE)
    ?.map((item) => (
      <tr key={item.id}>
        <td>{moment(item.commande.date).format("DD/MM/YYYY")}</td>
        <td>{item?.service?.libelle}</td>
        <td>
          <div className="photo">
            {/*<img src={item?.commande?.user?.photo ? item?.commande?.user?.photo : profil} alt="Profil" />*/}
            <img
              src={item?.commande?.user?.photo ?
                item?.commande?.user?.photo.startsWith(baseUrl) || item?.commande?.user?.photo.startsWith("https://lh3.googleusercontent.com/") ?
                  item?.commande?.user?.photo :
                  `${baseUrl}${item?.commande?.user?.photo.substring(item?.commande?.user?.photo.indexOf("/images/"))}`
                : profil}
              alt="Profil"

            />
          </div>
          {item?.commande?.user?.nom} {item?.commande?.user?.prenom}
        </td>
        <td>
          {item?.service?.libelle !== "Custom" ?
            <div className={item?.etat?.libelle === "Processing" ? "enprepa" : item?.etat?.libelle === "Completed" ? "livrer" : item?.etat?.libelle === "New" ? "nouveau" : "annuler"}>{item?.etat?.libelle === "Processing" ? "En préparation" : item?.etat?.libelle === "Completed" ? "Livré" : item?.etat?.libelle === "New" ? "Nouveau" : "Annulé"}</div>
            :
            <div className="custom">Custom</div>
          }
        </td>

        <td>{moment(item.dateRdv).format("DD/MM/YYYY")}</td>
        <td className="edit-content">
          <div className="action">
            <span>Edit</span>
            <div className="">
              <Link to={`/commande/${item.id}`}>Voir</Link>
              {item?.service?.libelle !== "Custom" &&
                <a href='#0' onClick={() => verify(item?.commande?.token_paiement)}>Etat de paiement</a>
              }
            </div>
          </div>
        </td>
      </tr>

    ));



  return (
    <>
      <div className="content-page">
        <div className="header">
          <h1>Gestion des Commandes</h1>
          <div className="notifications">
            <div className="icons"><img src={cloche} alt="Notifications" /></div>
          </div>
        </div>
        <div className="btn-add-form bloc-header-user">
          <label>Commandes</label>
          <select>
            <option value="">Tous</option>
            <option value="Options 1">Options 1</option>
          </select>
          <input type="text" name="" id="" placeholder="Search" />
        </div>

        <div className="content-listes">

          <table className="tableau-list list-commande">
            <thead>
              <tr>
                <th>Date de commandes</th>
                <th>Service</th>
                <th>Fan</th>
                <th>Le cas</th>
                <th>livraison avant</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentPageData}
            </tbody>
          </table>
        </div>


      </div>
    </>
  );

}

export default ListesCommande;
