import React, { Fragment, useEffect, useState } from 'react'
import Aside from '../Aside'
import ListesService from './ListesService'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { endpoints } from '../../utils/utils'
import Swal from 'sweetalert2'


const Service = () => {
  const [services, setServices] = useState([]);
  const axiosPrivate = useAxiosPrivate()

  let isMounted = true;
  const getServices = async () => {
    try {
      const response = await axiosPrivate.get(endpoints.getService);
      console.log(response.data);
      isMounted && setServices(response.data);
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }

  }
  useEffect(() => {
    
    const controller = new AbortController();

    

    getServices();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  const update = () => {
    getServices();
  }
  return (
    <Fragment>
      <Aside />
      <ListesService services={services} update={update} />
    </Fragment>
  );
}

export default Service;
