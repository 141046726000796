import React, { Fragment, useEffect, useState } from 'react'
import Aside from '../Aside'
import ListesFan from './ListesFan'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { endpoints } from '../../utils/utils'
import Swal from 'sweetalert2'


const Fan = () => {
  const [users, setUsers] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getUser);
        console.log("PageTotal", typeof response?.data?.users);
        isMounted && setUsers(JSON.parse(response.data.users));
        isMounted && setTotalPages(response.data.totalPages);


      } catch (err) {
        console.error(err);
        //navigate('/login', { state: { from: location }, replace: true });
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur est survenu au niveau du serveur'
        })
      }

    }

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    }
  },[])

  const update = () => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getUser);
        console.log(response.data);
        /* isMounted && */ setUsers(JSON.parse(response.data.users));
      } catch (err) {
        console.error(err);
        //navigate('/login', { state: { from: location }, replace: true });
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur est survenu au niveau du serveur'
        })
      }

    }

    getUsers();
  }
  return (
    <Fragment>
      <Aside />

      <ListesFan users={users} totalPages={totalPages} update={update} />
    </Fragment>
  );
}

export default Fan;
