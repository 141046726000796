import React, { Fragment, useEffect, useState } from 'react'
import Aside from '../Aside'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import ListesAbonnement from './ListesAbonnement'
import { useParams } from 'react-router-dom'
import { endpoints } from '../../utils/utils'
import Swal from 'sweetalert2'


const Abonnement = () => {
  const [commandes, setCommandes] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const getCommande = async () => {

    try {
      const response = await axiosPrivate.get(endpoints.getAbonnement + (id ? "/" + id : ""));
      console.log("Abonnements", response.data);
      /*isMounted &&*/ setCommandes(response.data);
      console.log("comcom0", commandes)
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }

  }
  useEffect(() => {

    const controller = new AbortController();





    getCommande();

    return () => {
      controller.abort();
    }
  })



  const update = () => {

    getCommande();
  }
  return (
    <Fragment>
      <Aside />
      <ListesAbonnement commandes={commandes} update={update} />
    </Fragment>
  );
}

export default Abonnement;
