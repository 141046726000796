import React, { useEffect, useState, } from "react";
import Aside from "../Aside";
import cloche from "../../assets/images/cloche.svg";
import profil from "../../assets/images/profil.png";
import sep from "../../assets/images/sep-commande.svg";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import moment from "moment";
import Swal from "sweetalert2";
import { LineWave } from "react-loader-spinner";
import ImagesGallery from "../Contenu/ImagesGallery";
import { endpoints } from "../../utils/utils";

const DetailCommande = () => {
  
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [photo, setPhoto] = useState("");
  const [commande, setCommande] = useState([]);
  const [etat, setEtat] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const [loading, setloading] = useState(false);
  const [imagesCover, setImagesCover] = useState();
  let isMounted = true;

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();

    if (
      commande?.etat?.libelle !== "New" &&
      (message == null ||
        message === undefined ||
        message === "" ||
        message === "null")
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Merci de selectioner un message",
      });
      setloading(false);
      return;
    }
    const formData = new FormData();
    formData.append("message", message);
    formData.append(
      "status",
      commande?.etat?.libelle === "New"
        ? "Processing"
        : commande?.etat?.libelle === "Processing"
        ? "Completed"
        : ""
    );
    if (id) formData.append("id", id);

    /* if (id && (photo == undefined || photo?.length == 0)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Merci de selectioner un média pour ce service'
      })
      setloading(false)
      return;
    }
 */

    formData.append("media", photo);

    try {
      console.log(id);
      const response = await axiosPrivate.post(
        endpoints.changeStatus,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data && response?.data?.status !== false) {
        /*  setSuccessMsg("Article ajouté"); */

        setloading(false);
        setMessage("");
        setPhoto("");
        setImagesCover("");
        console.log(response?.data);
        getCommande();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response?.data?.message,
        });
        setloading(false);
      }
    } catch (err) {
      /* setShowBtn(false)
      setActiveBtn("")
      if (!err?.response) {
          setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Erreur de chargement');
      }
      setShowBtn(false)
      setActiveBtn("") */
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
    /*  console.log(doc) */
  };

  const verify = async (token) => {
    try {
      const response = await axiosPrivate.post(
        endpoints.stripeVerify,
        JSON.stringify({ paymentIntentId: token }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data && response?.data?.status !== false) {
        setEtat(response?.data?.response);
      } else {
        setEtat("erreur");
      }
    } catch (err) {
      setEtat("erreur");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
    /*  console.log(doc) */
  };
  const getCommande = async () => {
    try {
      const response = await axiosPrivate.get(
        endpoints.getCommandeById + "/" + id
      );
      console.log(response);
      isMounted && setCommande(response.data);
      if (response?.data && response?.data?.status !== false) {
        verify(response?.data?.commande?.token_paiement);
        setMessage(response?.data?.admin_message);
        const selectedFIles = [];
        if (response?.data?.media?.filePath)
          selectedFIles.push(response?.data?.media?.filePath);

        setImagesCover(selectedFIles);
      }
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
  };
  useEffect(() => {
    const controller = new AbortController();

    getCommande();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleDeleteImageCover = (index, url) => {
    // Créez une copie de la liste d'images en supprimant l'image à l'index spécifié.
    const updatedImages = [];
    const updatedMedia = [];
    updatedImages.splice(index, 1);
    updatedMedia.splice(index, 1);

    setPhoto(updatedMedia);
    /* setRemovedImages(url) */

    // Mettez à jour la liste d'images.
    setImagesCover(updatedImages);
  };

  const handleMultipleImagesCover = (evnt) => {
    const selectedFIles = [];
    const targetFiles = evnt.target.files[0];
    const targetFilesObject = targetFiles;

    selectedFIles.push(URL.createObjectURL(targetFiles));

    setImagesCover(selectedFIles);
    setPhoto(targetFilesObject);
  };

  return (
    <>
      <Aside />
      <div className="content-page page-commande">
        <div className="header">
          <h1>
            Gestion des Commandes <span>Gestion des Commandes > Commande</span>
          </h1>
          <div className="notifications">
            <div className="icons">
              <img src={cloche} alt="Notifications" />
            </div>
          </div>
        </div>

        <div className="headerCommande">
          <div className="numeroCommande">
            <h2>Commande {commande?.id}</h2>
            <p>Le {moment(commande?.commande?.date).format("DD/MM/YYYY")}</p>
          </div>
          <div className="fanCommande">
            <img
              src={
                commande?.commande?.user?.photo
                  ? commande?.commande?.user?.photo
                  : profil
              }
              alt="Profil"
            />{" "}
            {commande?.commande?.user?.nom} {commande?.commande?.user?.prenom}
          </div>
          <div className="sepCommande">
            <img src={sep} alt="Profil" />
          </div>
          <div className="infoCommande">
            <div className="commande">Commande</div>
            {commande?.service?.libelle !== "Custom" ? (
              <>
                {commande?.etat?.libelle === "Processing" && (
                  <div className="enprepa">En préparation</div>
                )}
                {commande?.etat?.libelle === "New" && (
                  <div className="nouveau">Nouveau</div>
                )}
                {commande?.etat?.libelle === "Cancelled" && (
                  <div className="annuler">Annuler</div>
                )}
                {commande?.etat?.libelle === "Completed" && (
                  <div
                    className={
                      commande?.etat?.libelle === "Completed"
                        ? "livre termine"
                        : "livre"
                    }
                  >
                    Livrée
                  </div>
                )}
              </>
            ) : (
              <div className="custom">Custom</div>
            )}
          </div>
        </div>

        <div className="contentCommande">
          <form onSubmit={handleSubmit}>
            <div className="leftForm">
              <div className="form-text">
                <label>Service</label>
                <input
                  type="text"
                  readonly
                  value={commande?.service?.libelle}
                />
              </div>
              <div className="form-text">
                <label>Nom du fan</label>
                <input
                  type="text"
                  readonly
                  value={
                    commande?.commande?.user?.nom +
                    " " +
                    commande?.commande?.user?.prenom
                  }
                />
              </div>
              <div className="form-text">
                <label>Message</label>
                <textarea readOnly value={commande?.message}></textarea>
              </div>
            </div>

            <div className="rightForm">
              {commande?.service?.libelle !== "Custom" && (
                <>
                  <strong>Etat paiement :</strong> {etat}
                </>
              )}
              <div className="form-text">
                <label>Message</label>
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Votre message"
                  required={
                    commande?.etat?.libelle === "Processing" ? false : true
                  }
                  disabled={
                    commande?.etat?.libelle === "Processing" ||
                    commande?.service?.libelle === "Custom"
                      ? false
                      : true
                  }
                  value={message != null && message !== "null" ? message : ""}
                ></textarea>
              </div>

              <div className="form-text">
                <label>Ajouter un media</label>
                <input
                  onChange={handleMultipleImagesCover}
                  required={
                    commande?.etat?.libelle === "Processing" ||
                    commande?.service?.libelle === "Custom"
                      ? false
                      : true
                  }
                  disabled={
                    commande?.etat?.libelle === "Processing" ||
                    commande?.service?.libelle === "Custom"
                      ? false
                      : true
                  }
                  type="file"
                  id="file"
                  className="inputfile"
                />
                <label for="file">
                  Choisissez un fichier <span>ou faites-le glisser ici</span>
                </label>
              </div>

              <div>
                {imagesCover?.length > 0 && (
                  <ImagesGallery
                    images={imagesCover}
                    type={"video"}
                    handleDeleteImage={handleDeleteImageCover}
                    titre={"media"}
                  />
                )}
              </div>
              <div className="form-button">
                {loading ? (
                  <LineWave color={"#034B27"} loading={loading} size={50} />
                ) : (
                  <input
                    className={
                      commande?.etat?.libelle === "Cancelled" ||
                      commande?.etat?.libelle === "Completed"
                        ? "desactive"
                        : "false"
                    }
                    disabled={
                      commande?.etat?.libelle === "Cancelled" ||
                      commande?.etat?.libelle === "Completed"
                        ? true
                        : false
                    }
                    type="submit"
                    value={
                      commande?.etat?.libelle === "New"
                        ? "En préparation"
                        : commande?.etat?.libelle === "Processing"
                        ? "Livrée"
                        : commande?.etat?.libelle === "Cancelled"
                        ? "Annulée"
                        : "Terminée"
                    }
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DetailCommande;
