import React , { Fragment } from 'react'
import Aside from '../Aside'
import PaymentOffer from './PaymentOffer'

function Offer() {
  return (
    <Fragment>
      <Aside/>
      <PaymentOffer/>
    </Fragment>
  );
}

export default Offer;
