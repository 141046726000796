import React, { Fragment, useEffect, useState } from 'react'
import Aside from '../Aside'
import Lang from './Lang';



const ListesLang = () => {
 
  return (
    <Fragment>
      <Aside />

      <Lang />
    </Fragment>
  );
}

export default ListesLang;
