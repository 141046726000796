import React, { useEffect, useState } from 'react'
import Aside from '../Aside'
import cloche from '../../assets/images/cloche.svg';
import profil from '../../assets/images/profil.png';
import sep from '../../assets/images/sep-commande.svg';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import moment from 'moment';
import Swal from 'sweetalert2';
import { LineWave } from 'react-loader-spinner';
import { endpoints } from '../../utils/utils';



const DetailAbonnement = () => {


  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [photo, setPhoto] = useState("");
  const [commande, setCommande] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [loading, setloading] = useState(false);
  let isMounted = true;

  const handleSubmit = async (e) => {
    setloading(true)
    e.preventDefault();

    const formData = new FormData();
    formData.append('message', message);
    formData.append('status', commande?.etat?.libelle === "New" ? "Processing" : commande?.etat?.libelle === "Processing" ? "Completed" : "");
    if (id)
      formData.append('id', id)

    /* if (id && (photo == undefined || photo?.length == 0)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Merci de selectioner un média pour ce service'
      })
      setloading(false)
      return;
    }
 */

    formData.append('media', photo);

    try {
      console.log(id)
      const response = await axiosPrivate.post(endpoints.changeStatus,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },

        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data && response?.data?.status !== false) {
        /*  setSuccessMsg("Article ajouté"); */

        setloading(false)
        setMessage("");
        setPhoto("")
        console.log(response?.data)
        getCommande();
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response?.data?.response
        })
        setloading(false)
      }

    } catch (err) {
      /* setShowBtn(false)
      setActiveBtn("")
      if (!err?.response) {
          setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Erreur de chargement');
      }
      setShowBtn(false)
      setActiveBtn("") */
      setloading(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }
    /*  console.log(doc) */

  }
  const getCommande = async () => {

    try {
      const response = await axiosPrivate.get(endpoints.getCommande + "/" + id);
      console.log(response);
      isMounted && setCommande(response.data);
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }

  }
  useEffect(() => {
    const controller = new AbortController();

    getCommande();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])


  return (
    <>
      <Aside />
      <div className="content-page page-commande">
        <div className="header">
          <h1>Gestion des Commandes <span>Gestion des Commandes > Commande</span></h1>
          <div className="notifications">
            <div className="icons"><img src={cloche} alt="Notifications" /></div>
          </div>
        </div>

        <div className="headerCommande">
          <div className="numeroCommande">
            <h2>Commande {commande?.id}</h2>
            <p>Le {moment(commande?.commande?.date).format("DD/MM/YYYY")}</p>
          </div>
          <div className="fanCommande">
            <img src={profil} alt="Profil" /> {commande?.commande?.user?.nom} {commande?.commande?.user?.prenom}
          </div>
          <div className="sepCommande">
            <img src={sep} alt="Profil" />
          </div>
          <div className="infoCommande">
            <div className="commande">Commande</div>
            {commande?.etat?.libelle === "Processing" &&
              <div className="enprepa">En préparation</div>
            }
            {commande?.etat?.libelle === "Completed" &&
              <div className={commande?.etat?.libelle === "Completed" ? "livre termine" : "livre"}>Livrée</div>
            }
          </div>
        </div>

        <div className="contentCommande">
          <form onSubmit={handleSubmit}>
            <div className="leftForm">
              <div className="form-text">
                <label>Service</label>
                <input type="text" readonly value={commande?.service?.libelle} />
              </div>
              <div className="form-text">
                <label>Nom du fan</label>
                <input type="text" readonly value={commande?.commande?.user?.nom + " " + commande?.commande?.user?.prenom} />
              </div>
              <div className="form-text">
                <label>Description</label>
                <textarea readOnly value={commande?.service?.description}>
                </textarea>
              </div>
            </div>
            <div className="rightForm">
              <div className="form-text">
                <label>Message</label>
                <textarea onChange={(e) => setMessage(e.target.value)} placeholder="Votre message" required={commande?.etat?.libelle === "Processing" ? false : true} disabled={commande?.etat?.libelle === "Processing" ? false : true}></textarea>
              </div>
              <div className="form-text">
                <label>Ajouter un media</label>
                <input onChange={(e) => setPhoto(e.target.files[0])} required={commande?.etat?.libelle === "Processing" ? false : true} disabled={commande?.etat?.libelle === "Processing" ? false : true} type="file" id="file" />
                <label for="file">Choisissez un fichier <span>ou faites-le glisser ici</span></label>
              </div>

              <div className="form-button">

                {
                  loading ?

                    <LineWave color={'#034B27'} loading={loading} size={50} />
                    :

                    <input className={commande?.etat?.libelle === "Cancelled" || commande?.etat?.libelle === "Completed" ? "desactive" : "false"} disabled={commande?.etat?.libelle === "Cancelled" || commande?.etat?.libelle === "Completed" ? true : false} type="submit" value={commande?.etat?.libelle === "New" ? "En préparation" : commande?.etat?.libelle === "Processing" ? "Livrée" : commande?.etat?.libelle === "Cancelled" ? "Annulée" : "Terminée"} />
                }
              </div>
            </div>
          </form>
        </div>

      </div>
    </>
  );

}

export default DetailAbonnement;
