import React , { Fragment } from 'react'
import Aside from '../Aside'
import PaymentListe from './PaymentListe'

function Dashboard() {
  return (
    <Fragment>
      <Aside/>
      <PaymentListe/>
    </Fragment>
  );
}

export default Dashboard;
