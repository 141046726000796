import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import cloche from "../../assets/images/cloche.svg";
import croix from "../../assets/images/btn-fermer.svg";
import profil from "../../assets/images/profil.png";
import star from "../../assets/images/star.png";
import mahrez from "../../assets/images/mahrez.png";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { LineWave } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { API_BASE_URL, endpoints } from "../../utils/utils";
import CountryFlag from "react-country-flag";

const customStyles = {
  content: {
    width: "50%",
    top: "50%",
    left: "50%",
    OverflowY: "auto",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const ListesFan = ({ users, update, admin, totalPages }) => {

  const [nom, setNom] = useState("");
  const [usersF, setUsersF] = useState(null);
  const [allPage, setAllPage] = useState(-1);
  const [prenom, setPremom] = useState("");
  const [email, setEmail] = useState("");
  const [ville, setVille] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [pays, setPays] = useState("");
  const [phone, setPhone] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [numRue, setNumRue] = useState("");
  const [loading, setloading] = useState(false);
  const [imagesCover, setImagesCover] = useState();
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  // eslint-disable-next-line no-unused-vars
  const [id, setId] = useState(0);
  //
  const [photo, setPhoto] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();
  const openModal = (item) => {
    setCurrentUser(item);
    setIsOpen(true);
    setloading(false);
  };

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("prenom", prenom);
    formData.append("nom", nom);
    formData.append("email", email);
    if (currentUser.dateNaissance)
      formData.append(
        "date_naissance",
        moment(dateNaissance).format("YYYY/MM/DD")
      );
    formData.append("telephone", phone);
    formData.append("pays", pays);
    formData.append("ville", ville);
    formData.append("code_postale", codePostal);
    formData.append("numero_rue", numRue);
    if (currentUser.id) formData.append("id", currentUser.id);

    if (!currentUser.id && (photo === undefined || photo?.length === 0)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Merci de selectioner un média pour cette publication",
      });
      setloading(false);
      return;
    }

    formData.append("photo", photo);

    try {
      console.log(id);
      const response = await axiosPrivate.post(
        currentUser.id === 0 || currentUser.id === undefined
          ? endpoints.newUser
          : endpoints.updateUser,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data && response?.data?.status !== false) {
        /*  setSuccessMsg("Article ajouté"); */
        closeModal("");
        setloading(false);
        console.log(response?.data);
        update();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response?.data?.response,
        });
        setloading(false);
      }
    } catch (err) {
      /* setShowBtn(false)
      setActiveBtn("")
      if (!err?.response) {
          setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Erreur de chargement');
      }
      setShowBtn(false)
      setActiveBtn("") */
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
    /*  console.log(doc) */
  };
  const blockUser = async (id) => {
    //setCurrentUser(item)
    //setIsOpen(true);
    try {
      const response = await axiosPrivate.post(
        endpoints.bloqueUser,
        JSON.stringify({ id: id })
      );
      console.log(response.data);
      setCurrentUser(response.data);
      update();
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
  };

  const handleMultipleImagesPhoto = (evnt) => {
    const selectedFIles = [];
    const targetFiles = evnt.target.files[0];
    const targetFilesObject = targetFiles;

    selectedFIles.push(URL.createObjectURL(targetFiles));

    setImagesCover(selectedFIles);
    setPhoto(targetFilesObject);
  };
  const handleFilter = async (event) => {
    /* console.log("Bachir");
    console.log(users);
    if (event.target.value === "All")
      setUsersF(null);
    else
      setUsersF(users.filter(user => user.statusClient === event.target.value));
    // Créez une copie de la liste d'images en supprimant l'image à l'index spécifié. */
    setType(event.target.value);
    const fansFromApi = await fetchFans(1,event.target.value);
    setUsersF(fansFromApi)
  };

  const deblockUser = async (id) => {
    //setCurrentUser(item)
    //setIsOpen(true);
    try {
      const response = await axiosPrivate.post(
        endpoints.activeUser,
        JSON.stringify({ id: id })
      );
      console.log(response.data);
      setCurrentUser(response.data);
      update();
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [currentPage, setCurrentPage] = useState(0);
  const [type, setType] = useState("All");
  const [readonly, setReadOnly] = useState(true);
  const [currentUser, setCurrentUser] = useState(0);
  const PER_PAGE = 10;
// eslint-disable-next-line no-unused-vars
  const { getCode, getName } = require("country-list");
  const offset = currentPage * PER_PAGE;

  //const currentPageData = usersF != null ? usersF?.slice(offset, offset + PER_PAGE)
  const currentPageData = usersF != null ? usersF
    ?.map((item) => (
      <tr key={item?.id}>
        <td>
          <div className="photo">
            {/*<img src={item?.photo ? item?.photo : profil} alt="Profil" />*/}
            <img
              src={item?.photo ?
                item?.photo.startsWith(baseUrl) || item?.photo.startsWith("https://lh3.googleusercontent.com/") ?
                  item?.photo :
                  `${baseUrl}${item?.photo.substring(item?.photo.indexOf("/images/"))}`
                : profil}
              alt="Profil"

            />
          </div>{" "}
          {item?.nom} {item?.prenom}
        </td>
        {!admin && (
          <td>
            <div className="pays">
              {item?.pays && (
                <CountryFlag countryCode={getCode(item?.pays)} svg />
              )}
            </div>
            {item?.pays}
          </td>
        )}
        <td>{item?.email}</td>
        <td>
          <div className="status">
            {admin ? "" : <img src={star} alt="Profil" />}
          </div>{" "}
          {item?.statusClientAdmin}
        </td>
        <td className="edit-content edit-fan">
          <div className="action">
            <span>Edit</span>
            <div className="">
              <a className="edit" href="#0" onClick={() => openModal(item)}>
                Modifier
              </a>
            </div>
          </div>
        </td>
      </tr>
    )) : users
      ?.slice(offset, offset + PER_PAGE)
      ?.map((item) => (
        <tr key={item?.id}>
          <td>
            <div className="photo">
              {/*<img src={item?.photo ? item?.photo : profil} alt="Profil" />*/}
              <img
                src={item?.photo ?
                  item?.photo.startsWith(baseUrl) || item?.photo.startsWith("https://lh3.googleusercontent.com/") ?
                    item?.photo :
                    `${baseUrl}${item?.photo.substring(item?.photo.indexOf("/images/"))}`
                  : profil}
                alt="Profil"

              />
            </div>{" "}
            {item?.nom} {item?.prenom}
          </td>
          {!admin && (
            <td>
              <div className="pays">
                {item?.pays && (
                  <CountryFlag countryCode={getCode(item?.pays)} svg />
                )}
              </div>
              {item?.pays}
            </td>
          )}
          <td>{item?.email}</td>
          <td>
            <div className="status">
              {admin ? "" : <img src={star} alt="Profil" />}
            </div>{" "}
            {item?.statusClientAdmin}
          </td>
          <td className="edit-content edit-fan">
            <div className="action">
              <span>Edit</span>
              <div className="">
                <a className="edit" href="#0" onClick={() => openModal(item)}>
                  Modifier
                </a>
              </div>
            </div>
          </td>
        </tr>
      ));
  //const pageCount = Math.ceil(usersF != null ? usersF?.length / PER_PAGE : users?.length / PER_PAGE);


  const fetchFans = async (currentPage, type) => {

console.log(type)
    const res = await axiosPrivate.get(`${API_BASE_URL}/admin/users?page=${currentPage}&limit=10&type=${type}`);
    const data = await JSON.parse(res.data.users);
    setCurrentPage(currentPage-1);
    setAllPage(res.data.totalPages);
    return data;
  }
  const handlePageClick = async (data) => {
    //setCurrentPage(selectedPage);
    const fansFromApi = await fetchFans(data.selected + 1, type);
    setUsersF(fansFromApi)
  }

  useEffect(() => {
    console.log("Users", admin === "admin" ? false : true);
    setReadOnly(admin === "admin" ? false : true);
    /* setTimeout(() => {
        $(".pagination li:nth-child(2) a")[0].click();

    }, 100); */
    //handlePageClick({ selected: 1 });
    setCurrentPage(0);
    /*const pageCountRes = axiosPrivate.get(`${API_BASE_URL}/admin/users?page=1&limit=10`);
    setPageCount(pageCountRes.headers['total-pages']);*/
  }, [users]);

  return (
    <>

      <div className="content-page">
        <div className="header">
          <h1>Gestion {admin ? "User" : "Fans"}</h1>
          <div className="notifications">
            <div className="icons">
              <img src={cloche} alt="Notifications" />
            </div>
          </div>
        </div>
        <div className="btn-add-form bloc-header-user filter-fan">
          <label>{admin ? "User" : "Fans"}</label>
          <select onChange={handleFilter}>
            <option value="All">Filtre by</option>
            <option value="Free">Free</option>
            <option value="Fan">Fan</option>
            <option value="Member">Member</option>
          </select>
          <input type="text" name="" id="" placeholder="Search" />
        </div>

        <div className="content-listes">
          <table className="tableau-list">
            <tbody>{currentPageData}</tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          pageCount={allPage > -1 ? allPage : totalPages}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
          forcePage={currentPage}
        />
        <div className="btn-group">
          <a href="#0" className="add-user" onClick={() => openModal(null)}>
            Ajouter un user
          </a>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal">
          <div className="headerModal">
            <div className="date">
              <p>Date de création</p>
              <span>
                {moment(currentUser?.convertDate).format("DD/MM/YYYY")}
              </span>
            </div>
            <a href="#0" onClick={closeModal}>
              <img src={croix} alt="Fermer" />
            </a>
          </div>

          <div className="profil">
            <img
              src={
                currentUser?.photo
                  ? currentUser?.photo
                  : imagesCover
                    ? imagesCover
                    : mahrez
              }
              alt="Profil"
            />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-text">
              <label>Ajouter un media</label>
              <div className="file">
                <input
                  onChange={handleMultipleImagesPhoto}
                  type="file"
                  name="file"
                  id="file"
                  className="inputfile"
                />
                <label for="file">
                  Choisissez un fichier <span>ou faites-le glisser ici</span>
                </label>
              </div>
            </div>

            <div className="form-text name">
              <label>Nom {!admin && "et prénom"}</label>
              <input
                name="nom"
                required
                onChange={(e) => setNom(e.target.value)}
                type="text"
                readOnly={readonly}
                defaultValue={
                  admin
                    ? currentUser?.nom
                    : currentUser?.nom + " " + currentUser?.prenom
                }
              />
            </div>
            {admin && (
              <div className="form-text name">
                <label>Prénom</label>
                <input
                  name="prenom"
                  required
                  onChange={(e) => setPremom(e.target.value)}
                  type="text"
                  readOnly={readonly}
                  defaultValue={currentUser?.prenom}
                />
              </div>
            )}
            <div className="form-text email">
              <label>Email</label>
              <input
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                readonly={readonly}
                defaultValue={currentUser?.email}
              />
            </div>
            <div className="form-text tel">
              <label>Téléphone</label>
              <input
                name="phone"
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                readonly={readonly}
                defaultValue={currentUser?.phone}
              />
            </div>
            <div className="form-text age">
              <label>Your age</label>
              <input
                name="dateNaissance"
                onChange={(e) => setDateNaissance(e.target.value)}
                type="date"
                readonly={readonly}
                defaultValue={
                  currentUser?.date_naissance
                    ? moment(currentUser?.date_naissance).format("DD/MM/YYYY")
                    : ""
                }
              />
            </div>
            <div className="form-text adresse">
              <label>Street number</label>
              <input
                name="street"
                onChange={(e) => setNumRue(e.target.value)}
                type="text"
                readonly={readonly}
                defaultValue={currentUser?.numero_rue}
              />
            </div>
            <div className="form-text cp">
              <label>Code postal</label>
              <input
                name="street"
                onChange={(e) => setCodePostal(e.target.value)}
                type="text"
                readonly={readonly}
                defaultValue={currentUser?.code_postale}
              />
            </div>
            <div className="form-text ville">
              <label>Ville</label>
              <input
                name="ville"
                onChange={(e) => setVille(e.target.value)}
                type="text"
                readonly={readonly}
                defaultValue={currentUser?.ville}
              />
            </div>
            <div className="form-text country">
              <label>Country</label>
              <span className="country-flag">
                {currentUser?.pays && (
                  <CountryFlag countryCode={getCode(currentUser?.pays)} svg />
                )}
              </span>
              <input
                name="pays"
                onChange={(e) => setPays(e.target.value)}
                type="text"
                readonly={readonly}
                defaultValue={currentUser?.pays}
              />
            </div>
            <div className="form-button">
              {admin ? (
                loading ? (
                  <LineWave color={"#034B27"} loading={loading} size={50} />
                ) : (
                  <button name="">Sauvegarde</button>
                )
              ) : (
                <>
                  <Link to={`/commande/user/${currentUser.id}`}>Commandes</Link>
                  <Link to={`/abonnement/user/${currentUser.id}`}>
                    Abonnement
                  </Link>
                  {currentUser?.is_active === false ? (
                    <a href="#0" onClick={() => deblockUser(currentUser?.id)} name="">
                      Activer le compte
                    </a>
                  ) : (
                    <a href="#0"
                      onClick={() => blockUser(currentUser?.id)}
                      name=""
                      className="desactive"
                    >
                      Désactiver le compte
                    </a>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ListesFan;
