import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import mahrez from '../assets/images/riyad-mahrez.png';
import { endpoints } from '../utils/utils';


const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();


  const userRef = useRef();
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const [passwordType, setPasswordType] = useState("password");
  const [faClass, setFaClass] = useState("fa-regular fa-eye");
  const togglePassword = () => {
      if (passwordType === "password") {
          setPasswordType("text")
          setFaClass("fa-regular fa-eye-slash")
          return;
      }
      setPasswordType("password")
      setFaClass("fa-regular fa-eye")
  }

  useEffect(() => {
      userRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
      e.preventDefault();

     /*  console.log("params", params.id); */

      
          try {
              const response = await axios.post(endpoints.loginUrl,
                  JSON.stringify({ username: user, password: pwd }),
                  {
                      headers: { 'Content-Type': 'application/json' },
                      
                  }
              );
              if(response?.data?.data?.active){
                
                  const accessToken = response?.data?.token;
                  const roles = response?.data?.data?.roles;
                  const refresh = response?.data?.refresh_token;
                  const active = response?.data?.data?.active;
                  localStorage.setItem("refreshToken", response?.data?.refresh_token);
                  localStorage.setItem("accessToken", response?.data?.token);
                  console.log(accessToken)
                  setAuth({ user, pwd, roles, accessToken, refresh, active });
                  setUser('');
                  setPwd('');
                  const redirect = roles.filter(element => {
                      return element === "ROLE_SUPER_ADMIN"
                  })
  
                  if (redirect[0] === "ROLE_SUPER_ADMIN")
                      navigate("/fan", { replace: true })
                  else
                      {navigate("/", { replace: true })
                      console.log(response)
                    }
              }
              else
              setErrMsg('Votre compte n\'est pas actif');

          } catch (err) {
            console.log(err)
              if (!err?.response) {
                  setErrMsg('Erreur de connexion au serveur');
              } else if (err.response?.status === 400) {
                  setErrMsg('Missing Username or Password');
              } else if (err.response?.status === 401) {
                  setErrMsg('Adresse email ou mot de passe invalide');
              } else {
                  setErrMsg('Erreur serveur');
              }
              //errRef.current.focus();
          }
      


  }


  useEffect(() => {
      localStorage.setItem("persist", persist);
  }, [persist])

  return (
    <div className="main-login">
      <div className="container">
        <div className="admin-left">
          <div className="form-login">
            <form name="" id="" onSubmit={handleSubmit}>
              <p>Welcome Super Admin <br />to the word of </p>
              <h1><span>RIYAD</span> MAHREZ</h1>
              {errMsg ? <p className='error'>{errMsg}</p> : ""}
              <div className="form-text">
                <label>Email or Username</label>
                <input type="email" name="email" id="email" placeholder="Name@mail.com" className="email" ref={userRef} autoComplete="off" onChange={(e) => setUser(e.target.value)} value={user} required/>
              </div>
              <div className="form-text">
                <label>Password</label>
                <input type={passwordType} onChange={(e) => setPwd(e.target.value)} name="password" id="password" value={pwd} placeholder="Password" className="password" required />
                <div onClick={togglePassword}  className="eye"><a href="#0"  onClick={togglePassword} role="button" tabindex="0"><i onClick={togglePassword}  className={faClass}></i></a></div>
              </div>
              <a href="#0">I forgot my password?</a>
              <div className="form-submit">
                <input type="submit" name="sign" id="sign" value="Login" />
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right">
          <img src={mahrez} alt="Mahrez" />
        </div>
      </div>

    </div>
  );
}

export default Login;
