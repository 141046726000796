import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import cloche from '../assets/images/cloche.svg';
import diagramme from '../assets/images/diagramme.png';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { endpoints } from '../utils/utils';
function Dashboard() {
  const [views, setViews] = useState(0)
  const [likes, setLikes] = useState(0)
  const [comments, setComments] = useState(0)
  const [shares, setShares] = useState(0)
  const axiosPrivate = useAxiosPrivate()

  const convertToK = (number) => {
    if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K';
    }
    return number.toString();
  }


  const getStat = async () => {
    try {
      const response = await axiosPrivate.get(endpoints.getStats);
      console.log(response.data);
      if(response?.data?.status === true){
        setLikes(response?.data?.response?.like ?? 0)
        setComments(response?.data?.response?.comment ?? 0)
        setShares(response?.data?.response?.share ?? 0)
        setViews(response?.data?.response?.views ?? 0)
      }
      
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }

  }

  useEffect(() => {
   getStat()
  })
  
  return (
    <div className="content-page">
      <div className="header">
        <h1>Home</h1>
        <div className="notifications">
          <div className="icons"><img src={cloche} alt="Notifications" /></div>
        </div>
      </div>
      <h2>Dashboard</h2>
      <div className="full-width">
        <div className="bloc-stat">
          <div className="content">
            <p>Views</p>
            <h3>{convertToK(views)} <span>+11.01%</span></h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="content">
            <p>Like</p>
            <h3>{convertToK(likes)} <span>+11.01%</span></h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="content">
            <p>Comments</p>
            <h3>{convertToK(comments)}  <span>+11.01%</span></h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="content">
            <p>Shares</p>
            <h3>{convertToK(shares)}  <span>+11.01%</span></h3>
          </div>
        </div>

      </div>

      <div className="full-width">
        <div className="list-winner col-md-4">
          <div className="content">
            <h2>Sold</h2>
            <div className="img-diagramme">
              <img src={diagramme} className="avatar" alt="avatar" />
            </div>
          </div>
          <div className="content">
            <h2>Sold</h2>
            <div className="img-diagramme">
              <img src={diagramme} className="avatar" alt="avatar" />
            </div>
          </div>
        </div>

        <div className="list-winner col-md-8">
          <div className="content">
            <h2>Total amount sold day by day</h2>
            <div className="img-diagramme">
              <img src={diagramme} className="avatar" alt="avatar" />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Dashboard;
