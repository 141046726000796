import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LineWave } from 'react-loader-spinner';
import Modal from 'react-modal';
import ReactPaginate from "react-paginate";
import Swal from 'sweetalert2';
import croix from '../../assets/images/btn-fermer.svg';
import cloche from '../../assets/images/cloche.svg';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { endpoints } from '../../utils/utils';
import ImagesGallery from '../Contenu/ImagesGallery';
const customStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    OverflowY: 'auto',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const ListesService = ({ services, update, admin }) => {

  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [prix, setPrix] = useState("");
  const currentDate = new Date();
  const [setImagesCover] = useState();
  const [devise, setDevise] = useState("");
  const [stock, setStock] = useState("");
  const [devises, setDevises] = useState([]);
  const [loading, setloading] = useState(false);
  const [imagesPhoto, setImagesPhoto] = useState();
  const [id, setId] = useState(0);
  const [selectedDates, setSelectedDates] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [dateIntervals, setDateIntervals] = useState([]);
  //
  const [photo, setPhoto] = useState("");
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const axiosPrivate = useAxiosPrivate()
  const openModal = (item) => {
    setCurrentService(item)
    if (item) {
      setTitre(item.libelle)
      setId(item.id)
      setDescription(item.description)
      setPrix(item.prix)
      setDevise(item?.devise?.id)
      setStock(item.stock);
      const newDateIntervals = {};
      item?.availibility?.forEach((item, index) => {
        const formattedDate = new Date(item.date).toLocaleDateString();
        const startTime = item.timeStart;
        const endTime = item.timeEnd;

        newDateIntervals[formattedDate] = { startTime, endTime };
      });

      setDateIntervals(newDateIntervals);
      const selectedFIles = [];
      selectedFIles.push(item?.media?.filePath)
      setPhoto(selectedFIles);
      setImagesPhoto(selectedFIles);
    }
    else {
      setTitre("")
      setId(0)
      setDescription("")
      setPrix("")
      setDevise("")
      setStock("");
      setPhoto("");
      setImagesPhoto([])

      setDateIntervals([]);
      /* setPhoto(item?.media?.filePath);
      setImagesPhoto(item?.media?.filePath); */
    }
    setIsOpen(true);
    setloading(false)
  }

  let isMounted = true;
  const getDevises = async () => {
    try {
      const response = await axiosPrivate.get(endpoints.getDevise);
      console.log(response.data);
      isMounted && setDevises(response.data);
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }

  }

  const handleSubmit = async (e) => {
    setloading(true)
    e.preventDefault();
    console.log(dateIntervals);
    const jsonDateIntervals = JSON.stringify(dateIntervals);
    // formData.append('availability', jsonDateIntervals);
    //return;
    const formData = new FormData();
    formData.append('libelle', titre);
    formData.append('prix', prix);
    formData.append('media_file', photo);
    formData.append('stock', stock);
    formData.append('devise', devise);
    formData.append('description', description);
    formData.append('availibility', jsonDateIntervals);

    if (id)
      formData.append('id', id)
    if (id === 0 && (photo === undefined || photo.length === 0)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Merci de selectioner un média pour ce service'
      })
      setloading(false)
      return;
    }


    formData.append('photo', photo);

    try {
      console.log(id)
      const response = await axiosPrivate.post(id !== undefined && id !== 0 ? endpoints.updateService : endpoints.newService,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },

        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data && response?.data?.status !== false) {
        /*  setSuccessMsg("Article ajouté"); */
        closeModal("")
        setloading(false)
        console.log(response?.data)
        update()
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response?.data?.response
        })
        setloading(false)
      }

    } catch (err) {
      /* setShowBtn(false)
      setActiveBtn("")
      if (!err?.response) {
          setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Erreur de chargement');
      }
      setShowBtn(false)
      setActiveBtn("") */
      setloading(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }
    /*  console.log(doc) */

  }
  const handleMultipleImagesCover = (evnt) => {
    const selectedFIles = [];
    const targetFiles = evnt.target.files[0];
    const targetFilesObject = targetFiles

    selectedFIles.push(URL.createObjectURL(targetFiles))

    setImagesPhoto(selectedFIles);
    setPhoto(targetFilesObject)
  }

  const handleDateChange = (date) => {
    setStartDate(date)
    setSelectedDates([...selectedDates, date]);
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  const handleAddInterval = () => {
    if (selectedDates.length === 0 || !startTime || !endTime) return;
    const options = {
      year: 'numeric',
      month: '2-digit', // '2-digit' pour le mois (ex. '02' pour février)
      day: '2-digit',   // '2-digit' pour le jour (ex. '05' pour le 5ème jour)
    };
    const formattedDate = selectedDates[selectedDates.length - 1].toLocaleDateString('en-US', options);
    //const interval = `${formattedDate} : ${startTime} - ${endTime}`;

    setDateIntervals({ ...dateIntervals, [formattedDate]: { startTime, endTime } });
    setStartTime('');
    setEndTime('');

  };


  const handleDeleteImagePhoto = (index, url) => {
    // Créez une copie de la liste d'images en supprimant l'image à l'index spécifié.
    const updatedImages = [];
    const updatedMedia = [];
    updatedImages.splice(index, 1);
    updatedMedia.splice(index, 1);

    setPhoto(updatedMedia);
    /* setRemovedImages(url) */
    console.log(url)
    // Mettez à jour la liste d'images.
    setImagesCover(updatedImages);
  };

  const disable = async (id) => {
    //setCurrentUser(item)
    //setIsOpen(true);
    try {
      const response = await axiosPrivate.post(endpoints.disableService, JSON.stringify({ id: id }));
      console.log(response.data);
      setCurrentService(response.data)
      update()

    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [startDate, setStartDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [currentService, setCurrentService] = useState(0)
  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const currentPageData = services
    ?.slice(offset, offset + PER_PAGE)
    ?.map((item) => (
      <div className="elts">
        <div className="imgService">
          {/* <img src={item?.media?.filePath} alt="Mahrez" />*/}
          <img alt=''
            src={item?.media?.filePath.startsWith(baseUrl) || item?.media?.filePath.startsWith("https://lh3.googleusercontent.com/") ?
              item?.media?.filePath :
              `${baseUrl}${item?.media?.filePath.substring(item?.media?.filePath.indexOf("/images/"))}`}
          />
        </div>
        <div className="infoService">
          <h3>{item?.libelle}</h3>
          <div className="form-choice">
            <div className="form-check">
              <input onChange={() => disable(item.id)} checked={item.is_active ? true : false} type="checkbox" name="contenu" id={`free-${item.id}`} value="0" />
              <label for={`free-${item?.id}`}>{item?.is_active ? "en ligne" : "hors ligne"} <span></span></label>
            </div>
          </div>
          <div className="actionsServices">
            <span>edit</span>
            <div className="editRemove">
              <a href="#0" onClick={() => openModal(item)} className="edit">Modifier</a>
              <a href="#0" className="remove">Supprimer</a>
            </div>
          </div>
        </div>
      </div>
    ));
  const pageCount = Math.ceil(services?.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);

  }
  const deleteDateInterval = (formattedDate) => {
    const updatedDateIntervals = { ...dateIntervals };
    delete updatedDateIntervals[formattedDate];
    setDateIntervals(updatedDateIntervals);
  };

  useEffect(() => {
    getDevises();

    /* setTimeout(() => {
        $(".pagination li:nth-child(2) a")[0].click();

    }, 100); */
    handlePageClick({ selected: 0 })
    setCurrentPage(0)

  }, [services])

  return (
    <>
      <div className="content-page">
        <div className="header">
          <h1>Gestion {admin ? "User" : "des services"}</h1>
          <div className="notifications">
            <div className="icons"><img src={cloche} alt="Notifications" /></div>
          </div>
        </div>
        <div className="btn-add-form bloc-header-user">
          <label>{admin ? "User" : "Services"}</label>
          <select>
            <option value="">Filtre by</option>
            <option value="Options 1">Options 1</option>
          </select>
          <input type="text" name="" id="" placeholder="Search" />
        </div>

        <div className="content-listes">

          <div className="listeServices">
            {currentPageData}
            <div className="btnAdd">
              <a href="#0" onClick={() => openModal(null)}><span></span>Ajouter un Service</a>
            </div>
          </div>
        </div>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal contentContenu">
          <div className="headerModal">
            <h2>Ajouter un service</h2>
            <a href="#0" onClick={closeModal}><img src={croix} alt="Fermer" /></a>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="col-md-6">
              <div className="form-text">
                <label>Titre</label>
                <input onChange={(e) => setTitre(e.target.value)} required type="text" placeholder="Dorem ipsum dolorI " value={titre} />
              </div>
              <div className="form-text">
                <label>Description</label>
                <textarea onChange={(e) => setDescription(e.target.value)} required name="" id="" value={description}></textarea>
              </div>
              <div className="form-file">
                <label>Ajouter un média</label>
                <div className="file">
                  <input onChange={handleMultipleImagesCover} type="file" name="cover" id="cover" className="inputfile" />
                  <label for="cover">Choisissez un fichier <span>ou faites-le glisser ici</span></label>
                </div>
              </div>
              <div className="form-text form-number">
                <label>Stock dispo ( par mois)</label>
                <input value={stock} onChange={(e) => setStock(e.target.value)} required type="number" placeholder="10" />
              </div>
              <ul>
                {Object.entries(dateIntervals).map(([formattedDate, { startTime, endTime }]) => (
                  <li key={formattedDate}>
                    Date : {moment(formattedDate).format("DD/MM/YYYY")}<br />
                    Intervalles horaires : {startTime} - {endTime}
                    <button onClick={() => deleteDateInterval(formattedDate)}>Supprimer</button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-md-6">
              <ImagesGallery images={imagesPhoto} type={"photo"} handleDeleteImage={handleDeleteImagePhoto} titre={"cover"} />
              <div className="blocPrixDevise">
                <div className="form-text">
                  <label>Prix</label>
                  <input value={prix} onChange={(e) => setPrix(e.target.value)} required type="text" placeholder="1000" />
                </div>
                <div className="form-select">
                  <label>Devise</label>
                  <select value={devise} required onChange={(e) => setDevise(e.target.value)}>
                    <option value="">Devise</option>
                    {devises?.map((item) => <option value={item.id}>{item.libelle}</option>)}
                  </select>
                </div>
              </div>
              <div className="form-text form-date">
                <label>Date et heur disponibles</label>
                <input type="text" readonly value={moment(startDate).format("DD/MM/YYYY")} placeholder="28/28/2023" />
                <DatePicker minDate={currentDate} inline selected={startDate} onChange={handleDateChange} />
              </div>
              <div className="form-text text-time">
                <input
                  type="time"
                  placeholder="Heure de début (ex. 22:00)"
                  value={startTime}
                  onChange={handleStartTimeChange}
                />
                <input
                  type="time"
                  placeholder="Heure de fin (ex. 23:00)"
                  value={endTime}
                  onChange={handleEndTimeChange}
                />
                <button className='button-container' type='button' onClick={handleAddInterval}>+</button>
              </div>

            </div>
            <div className="form-button">
              {
                loading ?

                  <LineWave color={'#034B27'} loading={loading} size={50} />
                  :

                  <button type='submit' name="">Sauvegarde</button>
              }
            </div>
          </form>

        </div>
      </Modal>
    </>
  );

}

export default ListesService;
