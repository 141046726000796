import React from 'react'
function PaymentListe() {
  return (
    <div className="content-page">
      <h1>Payments</h1>
      <div className="full-width">
        <div className="bloc-stat">
          <div className="content">
            <p>Amount collected:</p>
            <h3>USD 0.00</h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="content">
            <p>Future commission due:</p>
            <h3>USD 0.00</h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="content">
            <p>Pending payments:</p>
            <h3>USD 0.00</h3>
          </div>
        </div>

      </div>

      <div className="content-listes text-center">
        <h2>Payment Pending</h2>
        <div className="btn-filter btn-add-form">
          <input type="submit" id="" value="Apply filter" />
        </div>
        <table className="tableau-list">
          <thead>
            <tr>
              <th>Name</th>
              <th>Number</th>
              <th>Payment</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SLB</td>
              <td>01</td>
              <td>USD 12.00</td>
              <td>edit</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="content-listes text-center">
        <h2>Payment Complted</h2>
        <div className="btn-filter btn-add-form">
          <input type="submit" id="" value="Apply filter" />
        </div>
        <table className="tableau-list">
          <thead>
            <tr>
              <th>Name</th>
              <th>Number</th>
              <th>Payment</th>
              <th>PDF</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SLB</td>
              <td>01</td>
              <td>USD 12.00</td>
              <td>pdf</td>
              <td>Invoice</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default PaymentListe;
