import React from 'react'
function PaymentOffer() {
  return (
    <div className="content-page">
      <h1>All Offers</h1>

      <div className="content-listes text-center">
        <div className="btn-filter btn-add-form">
          <input type="submit" id="" value="Apply filter" />
        </div>
        <table className="tableau-list">
          <thead>
            <tr>
              <th>Username</th>
              <th>Name</th>
              <th>Price</th>
              <th>Status</th>
              <th>Payment Type</th>
              <th>Sold active</th>
              <th>Sold archived</th>
              <th>Created</th>
              <th>Archive/Active</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SLB</td>
              <td>01</td>
              <td>USD 12.00</td>
              <td>edit</td>
              <td>SLB</td>
              <td>01</td>
              <td>USD 12.00</td>
              <td>edit</td>
              <td>SLB</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default PaymentOffer;
