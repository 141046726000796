import { useEffect } from "react";

const ImagesGallery = ({ images, type, handleDeleteImage, titre }) => {
    useEffect(() => {

    }, [images])

    return (
        <>
            <div className="apercuMedia">
                <p>Apercu du {titre}</p>
                <div className="file-list">
                    {
                        images && images?.map((url, index) => {
                            return (
                                type !== "videos" ?
                                    <>
                                        <div className="profil">
                                            <img src={url} alt="Profil" />
                                            <button onClick={() => handleDeleteImage(index, url)} type="button">X</button>
                                        </div>
                                    </>
                                    :

                                    <>
                                        <div className="profil">
                                            <video width="220" height="140" controls>
                                                <source src={url} type="video/mp4" />
                                                <source src={url} type="video/ogg" />
                                                Your browser does not support the video tag.
                                            </video>
                                            <button onClick={() => handleDeleteImage(index, url)} type="button">X</button>
                                        </div>
                                    </>
                            )
                        })
                    }
                </div>
            </div>
        </>

    )
}
export default ImagesGallery;