import React, { Fragment, useEffect, useState } from "react";
import Aside from "../Aside";
import ListesContenu from "./ListesContenu";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { endpoints } from "../../utils/utils";
import Swal from "sweetalert2";

const Contenu = () => {
  const [posts, setPosts] = useState([]);
  const [postsEp, setPostsEp] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getPost = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getPost);
        //console.log(response);
        console.log("PageTotal", response);
        isMounted && setPosts(response?.data?.posts);
        //console.log(posts)
      } catch (err) {
        console.error(err);
        //navigate('/login', { state: { from: location }, replace: true });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenu au niveau du serveur",
        });
      }
    };

    const getPostEp = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getPinnedPost);
       // const response2 = await axiosPrivate.get(endpoints.getComment);
       // console.log("test Commenteaires", response2);
        console.log(response);
        isMounted && setPostsEp(response?.data?.posts);
      } catch (err) {
        console.error(err);
        //navigate('/login', { state: { from: location }, replace: true });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenu au niveau du serveur",
        });
      }
    };

    getPost();
    getPostEp();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const update = () => {
    let isMounted = true;

    const getPost = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getPost);
        console.log(response.data);
        isMounted && setPosts(response.data.posts);
      } catch (err) {
        console.error(err);
        //navigate('/login', { state: { from: location }, replace: true });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenu au niveau du serveur",
        });
      }
    };

    getPost();
  };
  return (
    <Fragment>
      <Aside />
      <ListesContenu posts={posts} update={update} postsEp={postsEp} />
    </Fragment>
  );
};

export default Contenu;
