import React, { Fragment, useEffect, useState } from 'react'
import Aside from '../Aside'
import Message from './Message';



const ListesMessages = () => {
 
  return (
    <Fragment>
      <Aside />

      <Message />
    </Fragment>
  );
}

export default ListesMessages;
