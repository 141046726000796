
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import cloche from '../../assets/images/cloche.svg';
import croix from '../../assets/images/btn-fermer.svg';
import profil from '../../assets/images/profil.png';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Swal from 'sweetalert2';
import { LineWave } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import { endpoints } from '../../utils/utils';
const customStyles = {
  content: {
    width: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const ListesAbonnement = ({ commandes, update }) => {

  const axiosPrivate = useAxiosPrivate();
  const [loading, setloading] = useState(false);
  //const [successMsg, setSuccessMsg] = useState('');
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();
  //const [date, setDate] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const currentDate = new Date();
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  const desactiver = async (id) => {
    //setCurrentUser(item)
    //setIsOpen(true);
    try {
      Swal.fire({
        title: `abonnement désactivé`
      })
      update()

    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }
  }
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getUser);
        console.log("User Response", JSON.parse(response.data.users));
        console.log("Commandes Response", commandes)
       /* isMounted &&*/ setUsers(JSON.parse(response.data.users));
        console.log("USERS INIT", users);
      } catch (err) {
        console.error(err);
        //navigate('/login', { state: { from: location }, replace: true });
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur est survenu au niveau du serveur'
        })
      }

    }

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])
  const handleSubmit = async (e) => {
    setloading(true)
    e.preventDefault();

    if (startDate > endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Dates invalides'
      })
      setloading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(endpoints.newSouscription,
        JSON.stringify({ userId: user, dateDebut: moment(startDate).format("YYYY/MM/DD"), dateFin: moment(endDate).format("YYYY/MM/DD") }),
        {
          headers: { 'Content-Type': 'application/json' },

        }
      );
      if (response) {
        Swal.fire({
          title: `abonnement ajouté`
        })
        setloading(false);
        update();
        closeModal();
      }

    } catch (err) {
      console.log(err)
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err
      })
      setloading(false)
      //errRef.current.focus();
    }
    /*  console.log(doc) */

  }


  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0)
  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const currentPageData = commandes
    ?.slice(offset, offset + PER_PAGE)
    ?.map((item) => (
      <tr key={item.id}>
        <td>{moment(item?.date_start).format("DD/MM/YYYY")}</td>
        <td>{moment(item?.date_fin).format("DD/MM/YYYY")}</td>
        <td>
          <div className="photo">
            {/*<img src={item?.user?.photo ? item?.user?.photo : profil} alt="Profil" />*/}
            <img
              src={item?.user?.photo ?
                item?.user?.photo.startsWith(baseUrl) || item?.user?.photo.startsWith("https://lh3.googleusercontent.com/") ?
                  item?.user?.photo :
                  `${baseUrl}${item?.user?.photo.substring(item?.user?.photo.indexOf("/images/"))}`
                : profil}
              alt="Profil"

            />
          </div>
          {item?.user?.nom} {item?.user?.prenom}
        </td>
        <td>{item?.type}</td>
        {/* <td>
          <div className={item?.etat?.libelle == "Processing" ? "enprepa" :item?.etat?.libelle == "Completed" ? "livrer" : item?.etat?.libelle == "New" ? "nouveau" : "annuler"}>{item?.etat?.libelle == "Processing" ? "En préparation" :item?.etat?.libelle == "Completed" ? "Livré" : item?.etat?.libelle == "New" ? "Nouveau" : "Annulé"}</div>
        </td> */}
        {/* <td>{moment(item.dateRdv).format("DD/MM/YYYY")}</td> */}
        <td className="edit-content">
          <div className="action">
            <span>Edit</span>
            <div className="">
              {item?.renew ?
                <a href='#0' onClick={() => desactiver(item?.id)}>Désactiver</a>
                :
                <p>Pas de renouvelement</p>
              }
            </div>
          </div>
        </td>
      </tr>

    ));

  const pageCount = Math.ceil(commandes?.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);

  }
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="content-page">
        <div className="header">
          <h1>Gestion des Abonnements</h1>
          <div className="notifications">
            <div className="icons"><img src={cloche} alt="Notifications" /></div>
          </div>
        </div>
        <div className="btn-add-form bloc-header-user">
          <label>Abonnements</label>
          <select>
            <option value="">Tous</option>
            <option value="Options 1">Options 1</option>
          </select>
          <input type="text" name="" id="" placeholder="Search" />
        </div>

        <div className="content-listes">

          <table className="tableau-list list-commande">
            <thead>
              <tr>
                <th>Date d'abonnement</th>
                <th>Date de fin</th>
                <th>Fan</th>
                <th>Type</th>
                {/* <th>Le cas</th> */}
                {/* <th>livraison avant</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentPageData}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
        <a href="#0" className="add-user" onClick={() => openModal()}>Ajouter un Abonnement</a>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal contentContenu">
          <div className="headerModal">
            <h2>Ajouter une publication</h2>
            <a href='#0' onClick={closeModal}><img src={croix} alt="Fermer" /></a>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="col-md-6">
              <div className="form-select">
                <label>Utilisateur</label>
                <select onChange={(e) => setUser(e.target.value)} value={user} required>
                  <option></option>
                  {users?.map(item =>

                    <option key={item?.id} value={item?.id}>{item?.nom} {item?.prenom}</option>

                  )
                  }
                </select>
              </div>



            </div>


            <div className="col-md-6">
              <div className="form-text form-date">
                <label>Date de debut</label>
                <input type="text" readonly value={moment(startDate).format("YYYY/MM/DD")} placeholder="28/28/2023" />
                <DatePicker minDate={currentDate} inline selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>
              <div className="form-text form-date">
                <label>Date de fin</label>
                <input type="text" readonly value={moment(endDate).format("YYYY/MM/DD")} placeholder="28/28/2023" />
                <DatePicker minDate={currentDate} inline selected={endDate} onChange={(date) => setEndDate(date)} />
              </div>
            </div>

            <div className="form-button">
              {
                loading ?

                  <LineWave color={'#034B27'} loading={loading} size={50} />
                  :

                  <button name="">Sauvegarde</button>
              }

            </div>
          </form>

        </div>
      </Modal>
    </>
  );

}

export default ListesAbonnement;
