
import { Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import Accueil from './Components/Accueil';
import User from './Components/User/User';
import Fan from './Components/Fan/Fan';
import Service from './Components/Service/Service';
import Payment from './Components/Payment/Payment';
import Offer from './Components/Offer/Offer';
import Contenu from './Components/Contenu/Contenu';
import Commande from './Components/Commande/Commande';
import DetailCommande from './Components/Commande/DetailCommande';

import './App.css';
import RequireAuth from "./Components/RequireAuth";
import PersistLogin from "./Components/PersistLogin";
import Abonnement from "./Components/Abonnement/Abonnement";
import DetailAbonnement from "./Components/Abonnement/DetailAbonnement";
import ListesLang from "./Components/Lang/ListesLang";
import ListesMessages from "./Components/Messages/ListesMessages";

const ROLES = {
  'User': "ROLE_FAN",
  'Editor': "ROLE_USER",
  'Admin': "ROLE_SUPER_ADMIN"
}

function App() {
  console.log('test');
  return (

    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="accueil" element={<Accueil />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="fan" element={<Fan />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="users" element={<User />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="contenu" element={<Contenu />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="payment" element={<Payment />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="offer" element={<Offer />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="commande" element={<Commande />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="commande/:id" element={<DetailCommande />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="commande/user/:id" element={<Commande />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="abonnement" element={<Abonnement />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="abonnement/user/:id" element={<Abonnement />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="abonnement/:id" element={<DetailAbonnement />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="service" element={<Service />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="langues" element={<ListesLang />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="messages" element={<ListesMessages />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
