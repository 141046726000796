import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import Swal from "sweetalert2";
import croix from "../../assets/images/btn-fermer.svg";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { LineWave } from "react-loader-spinner";
import { endpoints } from "../../utils/utils";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Tags = ({ categories, update, lang }) => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setloading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [titre, setTitre] = useState();
  const [cover, setCover] = useState();
  const [id, setId] = useState(0);
  const [libelles, setLibelles] = useState({});

  const handleChange = (lang, value) => {
    setLibelles({ ...libelles, [lang]: value });
  };

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();

    const formData = new FormData();
    /* if (id != 0)
            formData.append('id', id)
        if (id != 0 && cover == undefined) {
            setloading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Merci de selectioner une image pour cette catégorie'
            })
            return;
        } */

    /* formData.append('libelle', titre);
        formData.append('media_file', cover); */

    try {
      console.log(id);
      const response = await axiosPrivate.post(
        id === 0 || id === undefined ? endpoints.newTag : endpoints.editTag,
        JSON.stringify({ libelle: titre, traductions: libelles, id : id }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data) {
        setSuccessMsg("tag ajouté");
        closeModal("");
        console.log(response?.data);
        update();
        setloading(false);
      }
    } catch (err) {
      setloading(false);
      /* setShowBtn(false)
            setActiveBtn("")
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Erreur de chargement');
            }
            setShowBtn(false)

            setActiveBtn("") */
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
    /*  console.log(doc) */
  };

  const openModal = (item) => {
    console.log(item);
    setloading(false);
    if (item != null) {
      setId(item?.id);
      setTitre(item?.libelle);
      setCover(item?.media?.filePath);
      setLibelles(item?.lang || {});
    } else {
      setTitre("");
      setCover("");
    }

    setIsOpen(true);
  };
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }
  function closeModal() {
    setIsOpen(false);
    setloading(false);
    setId(0);
    setTitre("");
    setLibelles({});
  }

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const currentPageData = categories
    ?.slice(offset, offset + PER_PAGE)
    ?.map((item) => (
      <>
        <tr key={item?.id}>
          <td>{item?.libelle}</td>
          <td className="edit-content">
            <div className="action">
              <span>Edit</span>
              <div className="">
                <a className="edit" href="#0" onClick={() => openModal(item)}>
                  Modifier
                </a>
              </div>
            </div>
          </td>
        </tr>
      </>
    ));
  const pageCount = Math.ceil(categories?.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  useEffect(() => {
    console.log(categories);
  }, [categories]);

  return (
    <>
      <div className="btn-add-form bloc-header-user">
        <label>Tags</label>
      </div>

      <div className="content-listes">
        <table className="tableau-list">
          <thead>
            <tr>
              <th>Titre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{currentPageData}</tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"←"}
        nextLabel={"→"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
      <a href="#0" className="add-user" onClick={() => openModal(null)}>
        Ajouter un Tag
      </a>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal contentContenu">
          <div className="headerModal">
            <h2>Ajouter un tag</h2>
            <a onClick={closeModal}>
              <img src={croix} alt="Fermer" />
            </a>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="col-md-6">
              <div className="form-text">
                <label>Titre</label>
                <input
                  required
                  type="text"
                  placeholder="Dorem ipsum dolorI "
                  onChange={(e) => setTitre(e.target.value)}
                  value={titre}
                />
              </div>
              <div className="form-text">
                <label>Traductions</label>
                {lang?.map((langItem) => (
                  <>
                  <label>{`Libellé (${langItem.lang})`}</label>
                  <input
                    key={langItem.id}
                    type="text"
                    value={libelles[langItem.lang] || ""}
                    onChange={(e) =>
                      handleChange(langItem.lang, e.target.value)
                    }
                    placeholder={`Libellé (${langItem.lang})`}
                  />
                  </>
                ))}
              </div>
            </div>
            <div className="form-button">
              {loading ? (
                <LineWave color={"#034B27"} loading={loading} size={50} />
              ) : (
                <button name="">Sauvegarde</button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default Tags;
