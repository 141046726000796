import React, { Fragment, useEffect, useState } from 'react'
import Aside from '../Aside'
import ListesCommande from './ListesCommande'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useParams } from 'react-router-dom'
import { endpoints } from '../../utils/utils'
import Swal from 'sweetalert2'


const Commande = () => {
  const [commandes, setCommandes] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  let isMounted = true;
  const getCommande = async () => {
   
    try {
      const response = await axiosPrivate.get(endpoints.getCommande + (id ? "/user/"+id : ""));
      console.log(response);
      isMounted && setCommandes(response.data);
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Une erreur est survenu au niveau du serveur'
      })
    }

  }
  useEffect(() => {
    
    const controller = new AbortController();  
    getCommande();
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])
  
 

  const update = () => {

    getCommande();
  }
  return (
    <Fragment>
      <Aside />
      <ListesCommande commandes={commandes} update={update} />
    </Fragment>
  );
}

export default Commande;
