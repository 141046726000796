import React , { Fragment } from 'react'
import Aside from './Aside'
import Accueil from './Accueil'

function Dashboard() {
  return (
    <Fragment>
      <Aside/>
      <Accueil/>
    </Fragment>
  );
}

export default Dashboard;
