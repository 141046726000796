import { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
/* eslint-disable no-unused-vars */
import { API_BASE_URL, endpoints } from "../../utils/utils";

const Comments = ({ comment, addComment, blockComment, totalPages, id }) => {

    const axiosPrivate = useAxiosPrivate();

    const [currentPage, setCurrentPage] = useState(0);

    const [commentF, setCommentF] = useState(null);

    const fetchComments = async (currentPage) => {


        const res = await axiosPrivate.get(`${API_BASE_URL}/admin/post/comment/${id}?page=${currentPage}&limit=10`);

        const data = await res.data.comments;

        return data;

    }

    const handlePageClick = async (data) => {



        const commentsFromApi = await fetchComments(data.selected + 1);

        setCommentF(commentsFromApi)

    }

    useEffect(() => {



        setCurrentPage(0);
        setCommentF(comment);

    }, [comment])

    return (

        <>

            <ul className="comment-component reply">

                {commentF?.map((commentaire, index) => (

                    <div key={index}>

                        <li className="comment" ><img src={commentaire.user.photo} alt=""/>{commentaire.user.nom} {commentaire.user.prenom} : {commentaire.content} <button onClick={() => addComment(commentaire, commentaire.user.nom)}>Répondre</button><button className={commentaire.isActive && "blocked"} onClick={() => blockComment(commentaire, commentaire.user.nom)}>{commentaire.isActive ? "Désactiver" : "Activer"}</button></li>

                        {commentaire?.comments &&

                            <ul className="sub-comment-component">

                                {commentaire?.comments?.map((item, index) => (



                                    <li className="sub-component" key={index}><img src={item.user.photo} alt="" />{item.user.nom} {item.user.prenom} : {item.content} <button onClick={() => addComment(commentaire, item.user.nom)}>Répondre</button><button className={item.isActive && "blocked"} onClick={() => blockComment(item, item.user.nom)}>{item.isActive ? "Désactiver" : "Activer"}</button></li>



                                ))}

                            </ul>

                        }

                    </ div>

                ))}

            </ul>

            <ReactPaginate

                previousLabel={"←"}

                nextLabel={"→"}

                pageCount={totalPages}

                onPageChange={handlePageClick}

                containerClassName={"pagination"}

                previousLinkClassName={"pagination__link"}

                nextLinkClassName={"pagination__link"}

                disabledClassName={"pagination__link--disabled"}

                activeClassName={"pagination__link--active"}

            />



        </>

    )

}

export default Comments;
