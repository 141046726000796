import axios from 'axios';
import { API_BASE_URL } from '../utils/utils';

const BASE_URL =  API_BASE_URL ;
//const BASE_URL = 'https://localhost:8001';


export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    //withCredentials: true
});

